import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Paper from '@mui/material/Paper';
import { useEffect, useState } from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
// import axios from "axios";
// import apiClient from "./common";
import "./App.css";
import {
  InstantSearch, SearchBox, Hits, Pagination,
  Highlight,
  ClearRefinements,
  RefinementList,
  Configure,
  SortBy,
} from 'react-instantsearch-dom';
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch';

const searchClient = instantMeiliSearch(
  `${process.env.REACT_APP_SEARCH_URL}`,
  `${process.env.REACT_APP_SEARCH_KEY}`,
  {
    primaryKey: 'id',
  }
)

const queryClient = new QueryClient()

function Hit(props: any) {

  const hitClick = (e: any) => {
    alert('hit click' + props.hit.title);
  }
  const { hit } = props;
  console.log("hit props ", hit)

  return (
    <div key={hit.id} onClick={hitClick}>
      <img src={hit.poster} alt={hit.title} />
      <div className="hit-title">
        <Highlight attribute="title" hit={hit} />
      </div>
      <div className="hit-overview">
        <Highlight attribute="overview" hit={hit} />
      </div>
    </div>
  );
}

const steps = [
  {
    label: 'Select campaign settings',
    description: `For each ad campaign that you create, you can control how much
              you're willing to spend on clicks and conversions, which networks
              and geographical locations you want your ads to show on, and more.`,
  },
  {
    label: 'Create an ad group',
    description:
      'An ad group contains one or more ads which target a shared set of keywords.',
  },
  {
    label: 'Create an ad',
    description: `Try out different ad text to see what brings in the most customers,
              and learn how to enhance your ads using features like ad extensions.
              If you run into any problems with your ads, find out how to tell if
              they're running and how to resolve approval issues.`,
  },
];

const sessionUrl = `${process.env.REACT_APP_API_URL}/v1/create-checkout-session`;

const ProductDisplay = () => (
  <section>
    <div className="product">
      <img
        src="https://i.imgur.com/EHyR2nP.png"
        alt="The cover of Stubborn Attachments"
      />
      <div className="description">
        <h3>Stubborn Attachments</h3>
        <h5>$20.00</h5>
      </div>
    </div>
    <form action={sessionUrl} method="POST">
      <button type="submit">
        Checkout
      </button>
    </form>
  </section>
)

const Message = (data: any) => (
  <section>
    <p>{data.message}</p>
  </section>
);


/* eslint-disable jsx-a11y/anchor-is-valid */
// Import FirebaseAuth and firebase.

// function Copyright() {
//   return (
//     <Typography variant="body2" color="text.secondary" align="center">
//       {'Copyright © '}
//       <Link color="inherit" href="https://mui.com/">
//         Your Website
//       </Link>{' '}
//       {new Date().getFullYear()}.
//     </Typography>
//   );
// }

// function App2() {
//   return (
//     <Container maxWidth="sm">
//       <Box sx={{ my: 4 }}>
//         <Typography variant="h4" component="h1" gutterBottom>
//           Create React App example with TypeScript
//         </Typography>
//         <ProTip />
//         <Copyright />
//       </Box>
//     </Container>
//   );
// }

// Configure Firebase.
const firebaseConfig = {
  apiKey: "AIzaSyCOHA6qP_GtM9PkUnN6YFGPHWRTZc08xHk",
  authDomain: "alertly-fca00.firebaseapp.com",
  projectId: "alertly-fca00",
  storageBucket: "alertly-fca00.appspot.com",
  messagingSenderId: "573278694309",
  appId: "1:573278694309:web:2310b328b0e945fae0164c",
  measurementId: "G-KND699HYBM"
};
firebase.initializeApp(firebaseConfig);

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    // firebase.auth.PhoneAuthProvider.PROVIDER_ID,
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
  ],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false,
  },
};

function Example(token: any) {
  // console.log('token', token)
  // const { isLoading, data, isFetching } = useQuery("repoData", () =>
  //   fetch(
  //     // "https://alertly.herokuapp.com/v1/something?id=1",
  //     "http://localhost:3010/v1/something?id=1",
  //     // "https://api.github.com/repos/tannerlinsley/react-query",
  //     // "https://qa-app-be.launchtrip.com/trips/v1/arrangement/list?order=ASC",
  //     // "http://localhost:3000/trips/v1/arrangement/list?order=ASC",
  //   ).then((res) => res.json())
  // );

  // if (!token) {
  //   console.log("No user");
  //   return;
  // }

  const url = `${process.env.REACT_APP_API_URL}/v1/auth/users/app-user`;

  const { isLoading, data, isFetching, error } = useQuery("userData", () =>
    fetch(
      url,
      {
        headers: {
          authorization: `Bearer ${token.token}`,
        }
      },
    ).then((res) => {
      // console.log(res.json().then((data)=> console.log(data)))
      // console.log("Ok done fetch", res.json());
      return res.json();
    })
      .catch((err) => {
        console.log("Here is the error", err);
      })
  );


  if (isLoading) return (
    <div>"Loading..." </div>
  )

  if (error) return <div>{"An error has occurred: " + (error as any).message}</div>;

  return (
    <div>
      <h1>{JSON.stringify(data)}</h1>
      <div>{isFetching ? "Updating..." : ""}</div>
    </div>
  );
}


function App() {
  const [isSignedIn, setIsSignedIn] = useState(false); // Local signed-in state.
  const [activeStep, setActiveStep] = React.useState(0);
  const [authenticatedUser, setAuthenticatedUser] = useState(undefined as string | undefined);

  const [message, setMessage] = useState("");


  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setMessage("Order placed! You will receive an email confirmation.");
    }

    if (query.get("canceled")) {
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, []);


  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged(async (user) => {
      const token = await user?.getIdToken(true);
      setAuthenticatedUser(token);
      setIsSignedIn(!!user);
    });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);

  if (!isSignedIn) {
    return (
      <Container maxWidth="sm">
        <Box sx={{ maxWidth: 400 }}>
          <h1>Tell me when</h1>
        </Box>
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
      </Container>
    );
  }
  return (
    <div className="ais-InstantSearch">
      <InstantSearch
        indexName="movies"
        searchClient={searchClient}
      >
        <QueryClientProvider client={queryClient}>
          <Container maxWidth="sm">
          <div className="left-panel">
        <ClearRefinements />
        <SortBy
          defaultRefinement="movies"
          items={[
            { value: "movies", label: "Relevant" },
            {
              value: "movies:recommendationCount:desc",
              label: "Most Recommended"
            },
            {
              value: "movies:recommendationCount:asc",
              label: "Least Recommended"
            }
          ]}
        />
        <h2>Genres</h2>
        <RefinementList attribute="genres" />
        <h2>Directors</h2>
        <RefinementList attribute="director" />
        <Configure
          hitsPerPage={6}
          attributesToSnippet={["description:50"]}
          snippetEllipsisText={"..."}
        />
      </div>
      <div className="right-panel">
        <SearchBox />
        <Hits hitComponent={Hit} />
        <Pagination showLast={true} />
      </div>
            <ProductDisplay />
            {message &&
              <Message message={message} />
            }
            <Example token={authenticatedUser} />
            <Box sx={{ maxWidth: 400 }}>
              <h1>Tell me when</h1>
              <p>Welcome {firebase?.auth()?.currentUser?.displayName}!</p>
              <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((step, index) => (
                  <Step key={step.label}>
                    <StepLabel
                      optional={
                        index === 2 ? (
                          <Typography variant="caption">Last step</Typography>
                        ) : null
                      }
                    >
                      {step.label}
                    </StepLabel>
                    <StepContent>
                      <Typography>{step.description}</Typography>
                      <Box sx={{ mb: 2 }}>
                        <div>
                          <Button
                            variant="contained"
                            onClick={handleNext}
                            sx={{ mt: 1, mr: 1 }}
                          >
                            {index === steps.length - 1 ? 'Finish' : 'Continue'}
                          </Button>
                          <Button
                            disabled={index === 0}
                            onClick={handleBack}
                            sx={{ mt: 1, mr: 1 }}
                          >
                            Back
                          </Button>
                        </div>
                      </Box>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
              {activeStep === steps.length && (
                <Paper square elevation={0} sx={{ p: 3 }}>
                  <Typography>All steps completed - you&apos;re finished</Typography>
                  <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                    Reset
                  </Button>
                </Paper>
              )}
            </Box>
            <a onClick={() => firebase.auth().signOut()}>Sign-out</a>
          </Container>
        </QueryClientProvider>
      </InstantSearch>
    </div>

  );
}

export default App;
